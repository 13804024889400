import LoadingProvider from "@components/common/loading";
import myAxios from "@config/myAxios";
import useGlobalStore from "@config/store/useGlobalStore";
import useThemeStore from "@config/store/useThemeStore";
import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import "@styles/globals.css";
import "@styles/output.css";
import Head from "next/head";
import Script from "next/script";
import React from "react";
import ReactGA from "react-ga";
import { SWRConfig } from "swr";

export const isBrowser = () => typeof window !== "undefined";

function MyApp(props) {
  const api = myAxios.init();
  const { Component, pageProps, router } = props;
  const GA_TRACKING_ID = "G-1KGVTS7LC8";
  ReactGA.initialize(GA_TRACKING_ID);
  const theme = useThemeStore((state) => state.theme);
  const [themeMode, setThemeMode] = React.useState(theme);
  const loading = useGlobalStore((state) => state.loading);
  const getLayout = Component.getLayout || ((page) => page);

  const toggleColorScheme = (value) => {
    setThemeMode(value || (themeMode === "light" ? "dark" : "light"));
  };

  // React.useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     gtag?.pageview(url);
  //   };
  //   router.events.on("routeChangeComplete", handleRouteChange);
  //   return () => {
  //     router.events.off("routeChangeComplete", handleRouteChange);
  //   };
  // }, [router.events]);

  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  // React.useEffect(() => {
  //   !(function () {
  //     let analytics = (window.analytics = window.analytics || []);
  //     if (!analytics.initialize)
  //       if (analytics.invoked)
  //         window.console &&
  //           console.error &&
  //           console.error("Segment snippet included twice.");
  //       else {
  //         analytics.invoked = !0;
  //         analytics.methods = [
  //           "trackSubmit",
  //           "trackClick",
  //           "trackLink",
  //           "trackForm",
  //           "pageview",
  //           "identify",
  //           "reset",
  //           "group",
  //           "track",
  //           "ready",
  //           "alias",
  //           "debug",
  //           "page",
  //           "once",
  //           "off",
  //           "on",
  //           "addSourceMiddleware",
  //           "addIntegrationMiddleware",
  //           "setAnonymousId",
  //           "addDestinationMiddleware",
  //         ];
  //         analytics.factory = function (e) {
  //           return function () {
  //             let t = Array.prototype.slice.call(arguments);
  //             t.unshift(e);
  //             analytics.push(t);
  //             return analytics;
  //           };
  //         };
  //         for (const element of analytics.methods) {
  //           let key = element;
  //           analytics[key] = analytics.factory(key);
  //         }
  //         analytics.load = function (key, e) {
  //           let t = document.createElement("script");
  //           t.type = "text/javascript";
  //           t.async = !0;
  //           t.src =
  //             "https://cdn.segment.com/analytics.js/v1/" +
  //             key +
  //             "/analytics.min.js";
  //           let n = document.getElementsByTagName("script")[0];
  //           n.parentNode.insertBefore(t, n);
  //           analytics._loadOptions = e;
  //         };
  //         analytics._writeKey = process.env.NEXT_PUBLIC_SEGMENT_KEY_PROD;
  //         analytics.SNIPPET_VERSION = "4.15.3";
  //         analytics.load(process.env.NEXT_PUBLIC_SEGMENT_KEY_PROD);
  //         analytics.page();
  //       }
  //   })();
  // }, []);

  return (
    <>
      <Head>
        <title>Engineers Cradle</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://i.ibb.co/xXfm2Qg/android-chrome-512x512.png"
        />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
            page_path: window.location.pathname,
          });
        `,
        }}
      />
      <SWRConfig
        value={{
          revalidateIfStale: false,
          revalidateOnFocus: true,
          revalidateOnReconnect: false,
          fetcher: (url) => api.global.get(url).then((res) => res.data),
        }}
      >
        <ColorSchemeProvider
          colorScheme={themeMode}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            white="#ffab00"
            withCSSVariables
            theme={{
              // colorScheme: themeMode,
              colorScheme: "dark",
              defaultRadius: "sm",
              primaryColor: "blue",
              dir: "ltr",
              loader: "dots",
              components: {},
            }}
          >
            <NotificationsProvider position="top-right" zIndex={1000} limit={3}>
              <ModalsProvider>
                <LoadingProvider loading={false}>
                  {getLayout(<Component {...pageProps} key={router.route} />)}
                </LoadingProvider>
              </ModalsProvider>
            </NotificationsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </SWRConfig>
    </>
  );
}

export default MyApp;
