import base from "./base";
import careerGoals from "./route/auth/careerGoals";
import organizations from "./route/auth/staticData/organizations";
import recommendations from "./route/auth/staticData/recommendations";
import global from "./route/global";

const bundle = {
  init(opts) {
    const client = base.init(opts);

    return {
      global: global.init(client),
      auth: {
        careerGoals: careerGoals.init(client),
      },
      staticData: {
        recommendations: recommendations.init(client),
        organizations: organizations.init(client),
      },
    };
  },
};

export default bundle;
