import Image from "next/image";

export default function LoadingProvider(p) {
  const props = {
    children: p.children,
    loading: p.loading || false,
  };

  return (
    <>
      {props.loading && (
        <div className="relative">
          <div className=" ec-absolute ec-items-center ec-justify-center ec-w-screen ec-h-screen ec-overflow-hidden ec-flex ec-z-[999]">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <h1 className=" ec-absolute">
              <Image
                alt="Image"
                className=" ec-animate-bounce"
                src="/assets/Logo_sm.svg"
                width="80"
                height="80"
              />
            </h1>
          </div>
        </div>
      )}
      {props.children}
    </>
  );
}
