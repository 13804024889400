const careerGoals = {
  init(base) {
    return {
      CreateCareerGoal(data) {
        return base.post("/auth/career-goals/create-career-goal", null, data);
      },
    };
  },
};

export default careerGoals;
