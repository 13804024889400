export const StaticDataOrganizationUrl = {
  createNewOrganization: "/admin/static/organizations/create-new-organization",
  getAllOrganizations: "/admin/static/organizations/get-all-organizations",
};

const staticData = {
  init(base) {
    return {
      createNewOrganization(data) {
        return base.post(
          StaticDataOrganizationUrl.createNewOrganization,
          null,
          data
        );
      },
      getAllOrganizations(query) {
        return base.get(StaticDataOrganizationUrl.getAllOrganizations, query);
      },
    };
  },
};

export default staticData;
