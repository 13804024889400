export const StaticDataCategoryUrl = {
  createNewCategory: "/admin/static/recommendations/create-new-category",
  getAllCategories: "/admin/static/recommendations/get-all-categories",
  addItemToCategory: "/admin/static/recommendations/add-item-to-category",
  removeItemFromCategory:
    "/admin/static/recommendations/remove-item-from-category",
};

const staticData = {
  init(base) {
    return {
      createNewCategory(data) {
        return base.post(StaticDataCategoryUrl.createNewCategory, null, data);
      },
      getAllCategories(data) {
        return base.get(StaticDataCategoryUrl.getAllCategories, data);
      },
      addItemToCategory(data) {
        return base.post(StaticDataCategoryUrl.addItemToCategory, null, data);
      },
      removeItemFromCategory(data) {
        return base.delete(StaticDataCategoryUrl.removeItemFromCategory, data);
      },
    };
  },
};

export default staticData;
